.resume {
    margin-left: 2rem;
    margin-right: 2rem;
    margin-top: 2rem;
    margin-bottom: 4rem;
}

.resumeHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.resumeName {
    font-family: "Oswald", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 800;
}

.resumeSection {
    margin-top: 1rem;
    margin-bottom: 1rem
}

.resumeSectionHeader {
    margin-bottom: 1rem;
}

.resumeSectionBody {
    margin-left: 1rem;
    margin-right: 1rem;
}

.resumeSectionGrid {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}

.resumeSectionGridItem {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 1rem;
}

.resumeEducationItem {
    margin-bottom: 1rem;
}