/* Overall Site Positioning & Defaults */
html, body, #root {
    box-sizing: border-box;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
    text-decoration: none;
}


.appBody {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: 100%;
}

/* Navigation Bar Placeholder (prevents content from displaying beneath navbar) */
.navigationSpacer {
    height: 64px;
    width: 100%;
}

/* Loading Spinner */
.loadingContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #FFFFFF;
}

.spinner {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: center;
}


/* Drawer */
.drawer-container {
    --transition-speed: 0.3s;
}

.drawer {
    background: #fff;
    height: 100%;
    overflow: auto;
    position: fixed;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    transition: transform var(--transition-speed) ease;
    z-index: 1000;
}

.drawer.left {
    top: 0;
    left: 0;
    transform: translateX(-105%);
}

.drawer.right {
    top: 0;
    right: 0;
    transform: translateX(100%);
}

.drawer.top {
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    transform: translateY(-100%);
    height: 40%;
}

.drawer.bottom {
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    transform: translateY(100%);
    height: 40%;
}

.drawer-container.in.open .left,
.drawer-container.in.open .right {
    transform: translateX(0);
}

.drawer-container.in.open .top,
.drawer-container.in.open .bottom {
    transform: translateY(0);
}

.backdrop {
    visibility: hidden;
    opacity: 0;
    background: rgba(0, 0, 0, 0.5);
    transition: opacity var(--transition-speed) ease,
    visibility var(--transition-speed) ease;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    pointer-events: none;
    z-index: 0;
}

.drawer-container.in.open .backdrop {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    z-index: 999;
}

.appBodyContent {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: center;

}

.contentOutlet {
    display: flex;
    flex-direction: column;
}


/* Desktop & Landscape View Padding (Adjusts for landscape screens > 750 pixels) */
@media (min-width: 750px) {
    .sidePadding {
        min-width: 5%;
    }
}

@media (min-width: 800px) {
    .sidePadding {
        min-width: 10%;
    }
}

@media (min-width: 900px) {
    .sidePadding {
        min-width: 15%;
    }
}

@media (min-width: 1000px) {
    .sidePadding {
        min-width: 20%;
    }
}

@media (min-width: 1100px) {
    .sidePadding {
        min-width: 25%;
    }
}

.sidePadding {
    display: flex;
    flex-direction: column;
}

